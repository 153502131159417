import React, { useState } from 'react';

import { PluginType } from '@grafana/data';

type Props = {
  className?: string;

  pluginId?: string;
  pluginType?: PluginType;

  angularSupportEnabled?: boolean;
  showPluginDetailsLink?: boolean;

  interactionElementId?: string;
};

// An Alert showing information about Angular deprecation notice.
// If the plugin does not use Angular (!plugin.angularDetected), it returns null.
export function AngularDeprecationPluginNotice(props: Props): React.ReactElement | null {
  const {  } = props;
  const [dismissed] = useState(false);
  return dismissed ? null : null
}
